const AppointmentState = {
    /**
     * 已发送，待处理
     */
    SENT: 0,

    /**
     * 已处理
     */
    TREATED: 1,

    /**
     * 已拒绝
     */
    REJECTED: 2,

    /**
     * 已撤回
     */
    RECALLED: 3,
}

export default AppointmentState;