import { render, staticRenderFns } from "./AppointmentList.vue?vue&type=template&id=bf9fd934&scoped=true&"
import script from "./AppointmentList.vue?vue&type=script&lang=js&"
export * from "./AppointmentList.vue?vue&type=script&lang=js&"
import style0 from "./AppointmentList.vue?vue&type=style&index=0&id=bf9fd934&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf9fd934",
  null
  
)

export default component.exports