<template>
  <div style="height: 100vh;" class="layout-vertical">
    <div class="layout-horizontal layout-middle layout-inflexible padding padding-large font-size-medium">
      <back-button @click="$router.back()"></back-button>
      <div class="font-weight-bold">客户预约</div>
    </div>
    <div class="layout-flexible layout-horizontal" style="overflow: hidden;">
      <div class="layout-inflexible">
        <ys-infinite-scroll-view style="height: 100%;" @loadmore="loadAppointmentListAfter">
          <div class="padding">
            <div
                class="list-item layout-horizontal layout-middle"
                v-for="appointment of this.appointmentList"
                :key="appointment.id"
                @click="handleAppointmentClick(appointment)"
            >
              <user-avatar :user="appointment.account"></user-avatar>
              <div class="margin-left layout-flexible">
                <div class="font-size-medium"
                     :class="{
                    'font-weight-bold': currentAppointment && currentAppointment.id === appointment.id
                  }">
                  <span>{{ appointment.account.nickName }}</span>
                </div>
                <div class="font-color-placeholder">
                  <span class="mark" v-if="appointment.state === AppointmentState.TREATED">已回复</span>
                  <span class="mark mark-rejected"
                        v-else-if="appointment.state === AppointmentState.REJECTED">已拒绝</span>
                  <span class="mark mark-sent" v-else-if="appointment.state === AppointmentState.SENT">待处理</span>
                  <span> · {{ TimeUtils.formatNormal(appointment.time) }}</span>
                </div>
              </div>
            </div>
          </div>
        </ys-infinite-scroll-view>
      </div>
      <div class="layout-flexible padding padding-large" v-if="currentAppointment">
        <div class="layout-horizontal layout-middle"
             style="max-width: 450px;"
        >
          <user-avatar size="large" :user="currentAppointment.account"></user-avatar>
          <div class="margin-left layout-flexible">
            <div class="font-size-large">{{ currentAppointment.account.nickName }}</div>
            <div class="font-color-secondary margin-top">预约时间：{{ TimeUtils.formatNormal(currentAppointment.time) }}
            </div>
            <div class="margin-top font-weight-bold">
              <span>状态：</span>
              <span v-if="currentAppointment.state === AppointmentState.SENT">待处理</span>
              <span v-else-if="currentAppointment.state === AppointmentState.REJECTED">已拒绝</span>
              <span v-else-if="currentAppointment.state === AppointmentState.TREATED">已回复</span>
            </div>
          </div>
          <div class="btn-user-management layout-inflexible" @click="handleUserMgmtClick">
            <span>用户管理 <i class="fas fa-angle-right"></i></span>
          </div>
        </div>
        <div class="message-paper padding padding-large margin-vertical-large">
          <div style="height: 0px;">
            <div class="font-align-right">
              <i class="fas fa-envelope-open"
                 style="position: relative; z-index: 0; font-size: 64px; color: #eed1a5; padding: 8px"></i>
            </div>
          </div>
          <div style="z-index: 1; position:relative;">
            <div class="font-color-light">预约消息：</div>
            <div class="message font-size-medium margin-top">
              <span v-if="currentAppointment.message">{{ currentAppointment.message }}</span>
              <span class="font-color-light" v-else>该用户什么也没填写</span>
            </div>
          </div>
        </div>
        <div class="margin-top-large">
          预约的干预方案：
          <span v-if="scheme" class="font-weight-bold">{{ scheme.title }}</span>
          <span v-else class="font-color-placeholder">无</span>
        </div>
        <div class="margin-top-large layout-horizontal">
          <div class="btn-operation layout-vertical layout-center"
               :class="{
                  'is-disable': currentAppointment.state !== AppointmentState.SENT,
               }"
               @click="handleMakeContractClick"
          >
            <ys-button type="secondary" lighting round
            ><i class="fas fa-pen-alt"></i></ys-button>
            <div class="font-size-small margin-top">签约</div>
          </div>
          <div class="btn-operation layout-vertical layout-center margin-left-large"
               :class="{
                  'is-disable': currentAppointment.state !== AppointmentState.SENT,
               }"
               @click="handleRejectAppointmentClick">
            <ys-button type="secondary" lighting round><i class="fas fa-user-slash font-color-danger"></i></ys-button>
            <div class="font-size-small margin-top">拒绝</div>
          </div>
        </div>
      </div>
    </div>
    <delete-dialog
        :visible.sync="rejectRemindDialogVisible"
        message="您确定要拒绝该顾客的预约请求吗？"
        confirm-button-text="拒绝"
        @confirm="handleRejectAppointmentConfirm"
    ></delete-dialog>
  </div>
</template>

<script>
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import BackButton from "@/components/backbutton/BackButton";
import httpapi from "@/assets/javascript/httpapi";
import UserAvatar from "@/components/user/UserAvatar";
import {TimeUtils} from "@/assets/javascript/time-utils";
import AppointmentState from "@/assets/javascript/AppointmentState";
import YsButton from "@/components/wedigets/YsButton";
import DeleteDialog from "@/components/dialog/DeleteDialog";

function doLoadAppointmentListAfter(id) {
  if (this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/appointment/list/get/by_organization/before',
    data: {
      id,
      organizationId: this.organizationId,
      count: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    if (id) {
      this.$appendAfter(this.appointmentList, res.data);
    } else {
      this.$appendBefore(this.appointmentList, res.data);
    }
    if (!this.currentAppointment && this.appointmentList.length > 0) {
      this.handleAppointmentClick(this.appointmentList[0]);
    }
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })

}

function doLoadAppointment(id) {
  if(this.loadingCode === this.busyLoadingCode) return;
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: '/appointment/get',
    data: {
      id,
    }
  }).then(res=>{
    if(loadingCode !== this.loadingCode) return;
    this.$appendBefore(this.appointmentList, [res.data]);
    if(this.currentAppointment?.id === id) {
      this.currentAppointment = res.data;
    }
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doTreatAppointment(id) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/appointment/treat/and/make/contract',
    data: {
      id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.success('签约成功');
    this.busyLoadingCode = 0;
    doLoadAppointment.bind(this)(id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRejectAppointment(id) {
  if(this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/appointment/reject',
    data: {
      id,
    }
  }).then(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
    doLoadAppointment.bind(this)(id);
  }).catch(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.$message.error('操作失败');
  }).complete(()=>{
    if(loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadSchemeById(id) {
  if (this.scheme?.id === id) return;
  this.$reqGet({
    path: '/scheme/get',
    data: {
      id,
    }
  }).then(res => {
    if (id === this.currentAppointment.schemeId) {
      this.scheme = res.data;
    }
  }).catch(() => {
    this.$message.error('加载失败');
  })
}

export default {
  name: "AppointmentList",
  mixins: [httpapi],
  components: {DeleteDialog, YsButton, UserAvatar, BackButton, YsInfiniteScrollView},
  props: {
    organizationId: Number,
  },
  data() {
    return {
      TimeUtils,
      AppointmentState,

      loadingCode: 1,
      busyLoadingCode: 0,

      appointmentList: [],

      currentAppointment: null,
      scheme: null,

      rejectRemindDialogVisible: false,
    }
  },
  watch: {
    organizationId: {
      handler() {
        this.loadingCode++;
        this.appointmentList = [];
        this.currentAppointment = null;
        this.scheme = null;
        this.loadAppointmentList();
      },
      immediate: true,
    }
  },
  methods: {
    loadAppointmentList() {
      doLoadAppointmentListAfter.bind(this)();
    },
    loadAppointmentListAfter() {
      if (this.appointmentList.length > 0) {
        doLoadAppointmentListAfter.bind(this)(this.appointmentList[this.appointmentList.length - 1].id);
      }
    },
    handleAppointmentClick(appointment) {
      this.currentAppointment = appointment;
      if (this.scheme?.id !== this.currentAppointment.schemeId) {
        this.scheme = null;
        if (this.currentAppointment.schemeId) {
          doLoadSchemeById.bind(this)(this.currentAppointment.schemeId);
        }
      }
    },
    handleUserMgmtClick() {
      let user = this.currentAppointment?.user;
      if(user) {
        this.$router.push({name: 'user', query: {id: user.id}})
      }
    },
    handleMakeContractClick() {
      if(this.currentAppointment?.state !== AppointmentState.SENT) return;
      if(this.currentAppointment) {
        doTreatAppointment.bind(this)(this.currentAppointment.id);
      }
    },
    handleRejectAppointmentClick() {
      if(this.currentAppointment?.state != AppointmentState.SENT) return;
      this.rejectRemindDialogVisible = true;
    },
    handleRejectAppointmentConfirm() {
      if(this.currentAppointment) {
        doRejectAppointment.bind(this)(this.currentAppointment.id);
      }
    }
  }
}
</script>

<style scoped>

.mark {
  line-height: 14px;
  font-size: 10px;
  border-radius: 4px;
  background-color: #f1f1f1;
  padding: 2px 4px;
}

.mark.mark-rejected {
  background-color: #f091a6;
  color: white;
  opacity: .5;
}

.mark.mark-sent {
  background-color: #f091a6;
  color: white;
}

.list-item {
  width: 300px;
}

.message-paper {
  background-color: #f0c78a;
  border-radius: 16px;
  box-shadow: 8px 8px 16px #bec0c0;
  max-width: 450px;
  overflow: hidden;
  box-sizing: border-box;
}

.message-paper .message {
  background: linear-gradient(transparent 30.5px, whitesmoke 1.5px);
  line-height: 32px;
  background-size: 100% 32px;
  background-attachment: local;
}

.btn-user-management {
  cursor: pointer;
}

.btn-operation {
  cursor: pointer;
}

.btn-operation.is-disable {
  opacity: .5;
}

</style>